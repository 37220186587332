import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";

export default function NotFound() {
  const PageTitle = "Page Not Found";
  return (
    <Layout>
      <Helmet title={`${PageTitle} | ${config.siteTitle}`} />
      <div id="content">
        <div id="contentInner">
          <div id="contentWrapper">
            <h2>{PageTitle}</h2>
            <div className="divider" />
            {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
            <p>{`Whoops! Looks like we can't find what you're looking for. Use the back button or click on our logo above to get back to our site.`}</p>
          </div>
        </div>
        <div className="contentInnerBottom" />
        <div className="contentBottom" />
      </div>
    </Layout>
  );
}
