const config = {
  siteTitle: "Light Touch Duo", // Site title.
  siteTitleShort: "Light Touch Duo", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: "Official Website of Light Touch Duo", // Alternative site title for SEO.
  siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
  siteUrl: "https://www.lighttouchduo.com", // Domain of your website without pathPrefix.
  pathPrefix: "/", // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    "The Light Touch Duo band will have you dancing into the night with classic music.", // Website description used for RSS feeds/meta description tag.
  copyright: `Unless Otherwise Noted All Content Herein is Copyright © 2007-${new Date().getFullYear()} ~ Light Touch Duo. All Rights Reserved.`, // Copyright string for the footer of the website and RSS feed.
  themeColor: "#fb4b2f", // Used for setting manifest and progress theme colors.
  backgroundColor: "#0e343c" // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
  config.pathPrefix = "";
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`;
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
  config.siteUrl = config.siteUrl.slice(0, -1);

module.exports = config;
