import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import config from "../../data/SiteConfig";
import "./index.css";

export default function MainLayout({ children, navLinks }) {
  return (
    <div id="container">
      <Helmet>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <div id="header">
        <h1>
          <Link to="/" title="light touch">
            home
          </Link>
        </h1>
        {navLinks && (
          <div id="navWrapper">
            <ul id="nav">
              {navLinks.map(
                ({
                  node: {
                    data: { PageId, LinkText, LinkTooltip }
                  }
                }) => (
                  <li className={PageId} key={PageId}>
                    <Link
                      activeClassName="current"
                      to={`/${PageId}`}
                      title={LinkTooltip}
                    >
                      {LinkText}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>
      {children}
      <div id="footer">
        <p>{config.copyright}</p>
      </div>
    </div>
  );
}
